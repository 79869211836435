body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* fixed social*/
.ff{
  display: flex;
  justify-content: end;
}
#fixed-social {
  position: fixed;
  top: 250px;
  z-index: 2147483647 !important;
  float: left;
}

#fixed-social a {
  color: #fff;
  display: block;
  height: 50px;
  position: relative;
  text-align: center;
   line-height: 50px;
  width: 50px;
  margin-bottom: 1px;
}
#fixed-social a:hover>span{
   visibility: visible;
 left: 41px;
 opacity: 1;
} 
#fixed-social a span {
   line-height: 50px;
   left: 50px;
   position: absolute;
   text-align: center;
   width: 120px;
   visibility: hidden;
   transition-duration: 0.5s;
   z-index: 1;
   opacity: 0;
}
.fixed-facebook{
    background-color: #454545;
    border-radius: 10px;
    margin-top: 5px;
}
.fixed-facebook span{
   background-color: #454545;
}
.fixed-twitter{
   background-color: #454545;
   border-radius: 10px;
   margin-top: 5px;

}
.fixed-twitter span{
    background-color: #454545;
}
.fixed-gplus{
   background-color:#454545 ;
   border-radius: 10px;
   margin-top: 5px;

}
.fixed-gplus span{
    background-color: #454545;
}
.fixed-linkedin{
   background-color: #454545;
   border-radius: 10px;
   margin-top: 5px;

}
.fixed-linkedin span{
    background-color: #454545;
}
.fixed-instagrem{
   background-color: #454545;
   border-radius: 10px;
   margin-top: 5px;

}
.fixed-instagrem span{
    background-color: #454545;
    border-radius: 10px;
    margin-top: 5px;
}
.fixed-tumblr{
   background-color: 	#454545;
   border-radius: 10px;
   margin-top: 5px;

}
.fixed-tumblr span{
    background-color: #454545;
    border-radius: 10px;
    margin-top: 5px;
}

/* styles/index.css */
.rpv-default-layout__container {
  background-color: 'black';
}

.rpv-core__viewer--dark .rpv-core__text-layer {
 opacity: 0.5;
}
.languages {
  display: flex;
}
.languages a {
}

 .languages img {
  width: 55px;
  height: 55px;
}
@media (max-width: 600px) {
  .nome{
    
  }
  
}
/*end fixed social*/